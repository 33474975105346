<template>
  <div class="head-container" style="float:right;">
    <!-- 搜索 -->
     <div class="filter-item">
      时间
      <el-date-picker
        style="width: 150px;z-index:99;"
        v-model="query.startTime"
        type="date"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  created() {
  },
  methods: {
    toQuery() {
      this.$parent.init()
    },
  }
}
</script>
