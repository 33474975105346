<template>
    <div class="base-chart"></div>
</template>

<script>
  /*传递入高度和配置項*/
  let echarts = require('echarts');
  export default {
    name: 'baseChart',
    props:{
      chartConfig: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        baseChart: null,
      }
    },
    watch:{
      chartConfig(){
        this.set();
      }
    },
    mounted(){
      this.initChart();
      let _this = this;
      this.baseChart.on('click', function (params) {
        /*点击事件*/
        _this.$emit('chart-click',params);
      });
      window.addEventListener('onresize',function() {
        _this.baseChart.resize();
      })
    },
    methods:{
      initChart(){
        this.baseChart = echarts.init(this.$el, 'macarons');
        this.baseChart.setOption(this.chartConfig);
      },
      set(){
        this.baseChart.clear();
        this.baseChart.setOption(this.chartConfig);
      },
    },
    beforeDestroy() {
      this.baseChart.dispose();
      this.baseChart = null;
    }
  };
</script>

<style scoped>
  .base-chart {
    height: 100%;
  }
</style>
